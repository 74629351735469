import React from 'react';
import {
  getIsTagLabelEnabled,
  getNumberOfTags,
  getTagAlignment,
  getTagHorizontalSpacing,
  getTagOrder,
  getTagShowPostCount,
  getTagVerticalSpacing,
} from '../../selectors/app-settings-selectors';
import { getTagTextAlignment, sortTags } from '../../services/tag-helpers';
import { useSelector } from '../runtime-context';
import Tag from './tag';
import TagsEmptyState from './tags-empty-state';
import styles from './tags.scss';

type Props = {
  postId: string;
  tags: any[];
  translations: {
    tagsLabel: string;
    emptyState: string;
    tags: {
      [tagId: string]: {
        a11yText: string;
        postCount: string;
      };
    };
  };
  isPostPageTags?: boolean;
  shouldSortTags?: boolean;
};

export function PostTags({
  postId,
  tags,
  translations,
  isPostPageTags,
  shouldSortTags = true,
}: Props) {
  const {
    isTagLabelEnabled,
    tagAlignment,
    tagVerticalSpacing,
    tagHorizontalSpacing,
    tagOrder,
    showPostCount,
    numberOfTags,
  } = useSlice(isPostPageTags);

  if (!tags?.length) {
    return isPostPageTags ? null : (
      <TagsEmptyState emptyState={translations.emptyState} />
    );
  }

  const tagWrapperStyles = {
    marginLeft: -tagHorizontalSpacing,
    marginTop: -tagVerticalSpacing,
  };

  const sortedTags = shouldSortTags ? sortTags(tags, tagOrder) : tags;
  const preparedTags = isPostPageTags
    ? sortedTags
    : sortedTags.slice(0, numberOfTags);

  return (
    <div
      style={{ textAlign: getTagTextAlignment(tagAlignment) }}
      className={isPostPageTags ? styles.postPageTagContainer : undefined}
    >
      {isTagLabelEnabled && (
        <p className={styles.tagLabel}>{translations.tagsLabel}</p>
      )}
      <nav
        aria-label="tags"
        className={styles.navigation}
        style={tagWrapperStyles}
      >
        <ul>
          {preparedTags.map(({ id, slug, label }) => (
            <Tag
              postId={postId}
              key={id}
              id={id}
              slug={slug}
              label={label}
              a11yText={translations.tags[id].a11yText}
              postCount={
                showPostCount ? translations.tags[id].postCount : undefined
              }
            />
          ))}
        </ul>
      </nav>
    </div>
  );
}

const useSlice = (isPostPageTags: boolean | undefined) => {
  return useSelector((state) => ({
    isTagLabelEnabled: getIsTagLabelEnabled(state),
    tagAlignment: getTagAlignment(state),
    tagVerticalSpacing: getTagVerticalSpacing(state),
    tagHorizontalSpacing: getTagHorizontalSpacing(state),
    tagOrder: getTagOrder(state),
    showPostCount: getTagShowPostCount(state) && !isPostPageTags,
    numberOfTags: getNumberOfTags(state),
  }));
};

export default PostTags;
